import { IAsyncEntity } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { IBaseApiErrorClientSide } from '@console/api-base/server/error/apiBaseError.models';
import { TRiskProfile, TRiskProfilesData } from '@console/core-api/models/portfolioMgmt/riskProfiles.models';
import { TGoal, TGoalsData } from '@console/core-api/models/portfolioMgmt/goal.models';
import { THorizon, THorizonsData } from '@console/core-api/models/portfolioMgmt/horizon.models';
import { IPolicyPerformanceFuture, TPoliciesData, TPolicy } from '@console/core-api/models/portfolioMgmt/policy.models';
import {
    IPortfolioPerformanceFuture, IPortfolioPerformancePast,
} from '@console/core-api/models/portfolioMgmt/portfolioPerformance.models';
import { IInstrumentPerformancePast } from '@console/core-api/models/portfolioMgmt/instrumentPerformancePast.models';
import { TInstrumentsData } from '@console/core-api/models/portfolioMgmt/instruments.models';
import { TUserGroup, TUserGroupsData } from '@console/core-api/models/userMgmt/userGroup.models';
import { TUsersData, TUser } from '@console/core-api/models/userMgmt/user.models';
import {
    TPortfolioTransactionsData,
} from '@console/core-api/models/portfolioMgmt/portfolioTransaction.models';
import { TPortfolioReportsData } from '@console/core-api/models/portfolioMgmt/portfolioReport.models';
import { TPortfolioGroup, TPortfolioGroupsData } from '@console/core-api/models/portfolioMgmt/portfolioGroups.models';
import { IApiEntityVersions } from '@console/core-api/models/apiEntityVersions.models';
import * as ConsoleBff from '@console/bff/export';
import { IEnhancedPortfolioHoldings } from '@console/bff/models/portfolios/portfolioHoldings.models';
import { IPolicyChain } from '@console/bff/models/policies/policyChain.models';
import { IEnhancedPortfolioAllocation } from '@console/bff/models/portfolios/portfolioAllocation.models';
import { IEnhancedPortfolioOptimization } from '@console/bff/models/portfolios/enhancedPortfolioOptimization.models';
import {
    IPortfolioOptimizationComparison,
} from '@console/bff/models/portfolios/portfolioOptimizationComparison.models';
import {
    TEnhancedPortfolioTransactionsData,
} from '@console/bff/models/portfolios/enhancedPortfolioTransactions.models';
import { TEnhancedPortfolioOptimizations } from '@console/bff/models/portfolios/enhancedPortfolioOptimizations.models';
import {
    IStoryManagerConfig,
    IStoryManagerDatabaseBase,
} from '@console/bff/models/storyteller/storymanager.models';
import {
    TPolicyOptimizationApiResponse,
} from '@console/bff/models/policies/policyOptimization.models';
import { TEnhancedPortfolio } from '@console/bff/models/portfolios/enhancedPortfolioDetails.models';
import { TEnhancedPortfoliosData } from '@console/bff/models/portfolios/enhancedPortfolios.models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICustomAsyncEntity<Data, Error = IBaseApiErrorClientSide> extends IAsyncEntity<Data, Error> {}

/* Keep in sync with the keys of IEntitiesState !! */
export enum AsyncEntityKeys {
    users = 'users',
    userDetails = 'userDetails',
    userPortfolios = 'userPortfolios',

    userGroups = 'userGroups',
    userGroupDetails = 'userGroupDetails',

    clients = 'clients',

    policies = 'policies',
    policyDetails = 'policyDetails',
    policyParentChain = 'policyParentChain',
    policyPerformanceFuture = 'policyPerformanceFuture',
    policyOptimization = 'policyOptimization',

    portfolios = 'portfolios',
    portfolioDetails = 'portfolioDetails',
    portfolioTransactions = 'portfolioTransactions',
    portfolioPerformance = 'portfolioPerformance',
    portfolioAllocation = 'portfolioAllocation',
    portfolioHoldings = 'portfolioHoldings',
    portfolioOptimization = 'portfolioOptimization',
    portfolioOptimizations = 'portfolioOptimizations',
    portfolioOptimizationComparison = 'portfolioOptimizationComparison',

    portfolioReports = 'portfolioReports',

    portfolioGroups = 'portfolioGroups',
    portfolioGroupDetails = 'portfolioGroupDetails',
    portfolioGroupPortfolios = 'portfolioGroupPortfolios',
    portfolioGroupReportPortfolios = 'portfolioGroupReportPortfolios',

    storyManagerDatabases = 'storyManagerDatabases',
    storyManagerDatabaseDetail = 'storyManagerDatabaseDetail',

    riskProfiles = 'riskProfiles',
    riskProfileDetails = 'riskProfileDetails',

    goals = 'goals',
    goalDetails = 'goalDetails',

    horizons = 'horizons',
    horizonDetails = 'horizonDetails',

    apiEntityVersions = 'apiEntityVersions',

    instruments = 'instruments',
    instrumentPerformancePast = 'instrumentPerformancePast',

    reportInfo = 'reportInfo',
    roboAumReport = 'roboAumReport',
    roboClientGrowthReport = 'roboClientGrowthReport',
    roboClientCashTransferReport = 'roboClientCashTransferReport',
    roboPortfolioPerformanceReport = 'roboPortfolioPerformanceReport',
    selfAumReport = 'selfAumReport',
    selfClientGrowthReport = 'selfClientGrowthReport',
    selfClientCashTransferReport = 'selfClientCashTransferReport',
}

/* Keep the keys in sync with ASYNC_ENTITY_KEYS !! */
export interface IEntitiesState {
    users: ICustomAsyncEntity<TUsersData>;
    userDetails: ICustomAsyncEntity<TUser>;
    userPortfolios: ICustomAsyncEntity<TEnhancedPortfoliosData>;

    userGroups: ICustomAsyncEntity<TUserGroupsData>;
    userGroupDetails: ICustomAsyncEntity<TUserGroup>;

    clients: ICustomAsyncEntity<TUsersData>;

    policies: ICustomAsyncEntity<TPoliciesData>;
    policyDetails: ICustomAsyncEntity<TPolicy>;
    policyParentChain: ICustomAsyncEntity<IPolicyChain>;
    policyPerformanceFuture: ICustomAsyncEntity<IPolicyPerformanceFuture>;
    policyOptimization: ICustomAsyncEntity<TPolicyOptimizationApiResponse>;

    portfolios: ICustomAsyncEntity<TEnhancedPortfoliosData>;
    portfolioDetails: ICustomAsyncEntity<TEnhancedPortfolio>;
    portfolioCashTransactions: ICustomAsyncEntity<TPortfolioTransactionsData>;
    portfolioTransactions: ICustomAsyncEntity<TEnhancedPortfolioTransactionsData>;
    portfolioPerformancePast: ICustomAsyncEntity<IPortfolioPerformancePast>;
    portfolioPerformanceFuture: ICustomAsyncEntity<IPortfolioPerformanceFuture>;
    portfolioAllocation: ICustomAsyncEntity<IEnhancedPortfolioAllocation>;
    portfolioHoldings: ICustomAsyncEntity<IEnhancedPortfolioHoldings>;
    portfolioOptimization: ICustomAsyncEntity<IEnhancedPortfolioOptimization>;
    portfolioOptimizations: ICustomAsyncEntity<TEnhancedPortfolioOptimizations>;
    portfolioOptimizationComparison: ICustomAsyncEntity<IPortfolioOptimizationComparison>;

    portfolioReports: ICustomAsyncEntity<TPortfolioReportsData>;

    portfolioGroups: ICustomAsyncEntity<TPortfolioGroupsData>;
    portfolioGroupDetails: ICustomAsyncEntity<TPortfolioGroup>;
    portfolioGroupPortfolios: ICustomAsyncEntity<TEnhancedPortfoliosData>;
    portfolioGroupReportPortfolios: ICustomAsyncEntity<TEnhancedPortfoliosData>;

    storyManagerDatabases: ICustomAsyncEntity<IStoryManagerDatabaseBase[]>;
    storyManagerConfig: ICustomAsyncEntity<IStoryManagerConfig>;

    riskProfiles: ICustomAsyncEntity<TRiskProfilesData>;
    riskProfileDetails: ICustomAsyncEntity<TRiskProfile>;

    goals: ICustomAsyncEntity<TGoalsData>;
    goalDetails: ICustomAsyncEntity<TGoal>;

    horizons: ICustomAsyncEntity<THorizonsData>;
    horizonDetails: ICustomAsyncEntity<THorizon>;

    apiEntityVersions: ICustomAsyncEntity<IApiEntityVersions>;

    instruments: ICustomAsyncEntity<TInstrumentsData>;
    instrumentPerformancePast: ICustomAsyncEntity<IInstrumentPerformancePast>;

    reportInfo: ICustomAsyncEntity<ConsoleBff.TReportInfoBff>;
    roboAumReport: ICustomAsyncEntity<ConsoleBff.IFetchRoboAumReportApiReply>;
}

export interface IForceStateRefreshFilter {
    forceRefresh?: boolean;
}
