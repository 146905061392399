/**
 * When a notification is triggered, by default also the parent notifications (delimited by a .)
 * get triggered.
 * Example:
 *   triggering I18N.TRANSLATIONS.TOGGLE
 *   results in the trigger of both I18N.TRANSLATIONS.TOGGLE and I18N.TRANSLATIONS and I18n
 *
 * p.s. the number of parent levels to get triggered can be overruled per action.
 */
export enum StateChangeNotification {
    AUTH = 'AUTH',
    AUTH_USER = 'AUTH.USER',
    AUTH_REDIRECT_URL = 'AUTH.REDIRECT_URL',
    AUTH_FLOW_STATUS = 'AUTH.FLOW_STATUS',

    APP_CONFIG_TENANT = 'APP_CONFIG.TENANT',

    I18N_TRANSLATIONS = 'I18N.TRANSLATIONS',
    I18N_TRANSLATIONS_REFRESHED = 'I18N.TRANSLATIONS.REFRESHED',
    I18N_TRANSLATIONS_TOGGLE = 'I18N.TRANSLATIONS.TOGGLE',

    UI_ROUTE = 'UI.ROUTE',
    UI_ROUTE_KEY = 'UI.ROUTE.KEY',
    UI_APP_SHELL_CONFIG = 'UI.APP_SHELL_CONFIG',
    UI_APP_MENU = 'UI.APP_MENU',
    UI_APP_MODALS = 'UI.APP_MODALS',
    UI_FLASH_MESSAGES = 'UI.FLASH_MESSAGES',
    UI_TOOLTIPS_DISMISSED = 'UI.TOOLTIPS.DISMISSED',
    UI_TOOLTIPS_AUTO_SHOW = 'UI.TOOLTIPS.AUTO_SHOW',
    UI_LIST_PAGE_SEARCH = 'UI.LIST_PAGE.SEARCH',
    UI_LIST_PAGE_SEARCH_MODE = 'UI.LIST_PAGE.SEARCH.MODE',
    UI_LIST_PAGE_SEARCH_SIMPLE = 'UI.LIST_PAGE.SEARCH.SIMPLE',
    UI_LIST_PAGE_SEARCH_ADVANCED = 'UI.LIST_PAGE.SEARCH.ADVANCED',
    UI_LIST_PAGE_PAGINATION = 'UI.LIST_PAGE.PAGINATION',
    UI_PAGE_PORTFOLIO_PERFORMANCE = 'UI.PAGE.PORTFOLIO_PERFORMANCE',
    UI_PAGE_PORTFOLIO_PERFORMANCE_MODE = 'UI.PAGE.PORTFOLIO_PERFORMANCE.MODE',
    UI_PAGE_PORTFOLIO_PERFORMANCE_FILTER = 'UI.PAGE.PORTFOLIO_PERFORMANCE.FILTER',
    UI_PAGE_PORTFOLIO_PERFORMANCE_PAST_MARKED_DATE = 'UI.PAGE.PORTFOLIO_PERFORMANCE.PAST.MARKED_DATE',
    UI_PAGE_PORTFOLIO_PERFORMANCE_FUTURE_MARKED_DATE = 'UI.PAGE.PORTFOLIO_PERFORMANCE.FUTURE.MARKED_DATE',
    UI_PAGE_ACCESSIBLE_BY = 'UI.PAGE.ACCESSIBLE_BY',
    UI_PAGE_POLICY_SIMULATION = 'UI.PAGE.POLICY_SIMULATION',
    UI_PAGE_POLICY_PERFORMANCE_FUTURE_MARKED_DATE = 'UI.PAGE.POLICY_PERFORMANCE.FUTURE.MARKED_DATE',
    UI_PAGE_INSTRUMENT_PERFORMANCE_PAST_MARKED_DATE = 'UI.PAGE.INSTRUMENT_PERFORMANCE.PAST.MARKED_DATE',
    UI_PAGE_USERGROUP_MEMBERS_FILTER = 'UI.PAGE.USERGROUP.MEMBERS.FILTER',
    UI_PAGE_UNIVERSE_INSTRUMENTS_FILTER = 'UI.PAGE.UNIVERSE_INSTRUMENTS.FILTER',
    UI_PAGE_STORY_MANAGER = 'UI.PAGE.STORY_MANAGER',
    UI_PAGE_STORY_MANAGER_EDITOR_MODE = 'UI.PAGE.STORY_MANAGER.EDITOR_MODE',
    UI_PAGE_STORY_MANAGER_FILTER = 'UI.PAGE.STORY_MANAGER.FILTER',
    UI_PAGE_STORY_MANAGER_SIDE_NAV_OUTPUT_KEYS = 'UI.PAGE.STORY_MANAGER.SIDE_NAV.OUTPUT_KEYS',
    UI_PAGE_STORY_MANAGER_SIDE_NAV_WIDTH = 'UI.PAGE.STORY_MANAGER.SIDE_NAV.WIDTH',

    USERS_DATA = 'USERS.DATA',
    USERS_UI_VARS = 'USERS.UI_VARS',

    USER_SEARCH_UI_VARS = 'USER.SEARCH.UI_VARS',
    USER_DETAILS_DATA = 'USER.DETAILS.DATA',
    USER_PORTFOLIOS_DATA = 'USER.PORTFOLIOS.DATA',
    USER_PORTFOLIOS_UI_VARS = 'USER.PORTFOLIOS.UI_VARS',

    USERGROUPS_DATA = 'USER_GROUPS.DATA',
    USERGROUPS_UI_VARS = 'USER_GROUPS.UI_VARS',

    USERGROUP_DETAILS_DATA = 'USER_GROUP.DETAILS.DATA',
    USERGROUP_MEMBERS_UI_VARS = 'USER_GROUP.MEMBERS.UI_VARS',
    USERGROUP_PERMISSIONS_UI_VARS = 'USER_GROUP.PERMISSIONS.UI_VARS',

    CLIENTS_DATA = 'CLIENTS.DATA',
    CLIENTS_UI_VARS = 'CLIENTS.UI_VARS',

    POLICIES_DATA = 'POLICIES.DATA',
    POLICIES_UI_VARS = 'POLICIES.UI_VARS',
    POLICY_SEARCH_UI_VARS = 'POLICY.SEARCH.UI_VARS',
    POLICY_DETAILS_DATA = 'POLICY.DETAILS.DATA',
    POLICY_PARENT_CHAIN_DATA = 'POLICY.PARENT_CHAIN.DATA',
    POLICY_PERFORMANCE_FUTURE_DATA = 'POLICY_PERFORMANCE_FUTURE_DATA',
    POLICY_OPTIMIZATION = 'POLICY.OPTIMIZATION',

    PORTFOLIOS_DATA = 'PORTFOLIOS.DATA',
    PORTFOLIOS_UI_VARS = 'PORTFOLIOS.UI_VARS',
    PORTFOLIO_SELECT_UI_VARS = 'PORTFOLIO.SELECT.UI_VARS',

    PORTFOLIO_DETAILS_DATA = 'PORTFOLIO.DETAILS.DATA',
    PORTFOLIO_TRANSACTIONS_DATA = 'PORTFOLIO.TRANSACTIONS.DATA',
    PORTFOLIO_TRANSACTIONS_UI_VARS = 'PORTFOLIO.TRANSACTIONS.UI_VARS',
    PORTFOLIO_PERFORMANCE_PAST_DATA = 'PORTFOLIO.PERFORMANCE.PAST.DATA',
    PORTFOLIO_PERFORMANCE_FUTURE_DATA = 'PORTFOLIO.PERFORMANCE.FUTURE.DATA',
    PORTFOLIO_ALLOCATION = 'PORTFOLIO.ALLOCATION',
    PORTFOLIO_HOLDINGS = 'PORTFOLIO.HOLDINGS',
    PORTFOLIO_OPTIMIZATION = 'PORTFOLIO.OPTIMIZATION',
    PORTFOLIO_OPTIMIZATIONS_DATA = 'PORTFOLIO.OPTIMIZATIONS.DATA',
    PORTFOLIO_OPTIMIZATIONS_UI_VARS = 'PORTFOLIO.OPTIMIZATIONS.UI_VARS',
    PORTFOLIO_OPTIMIZATION_COMPARISON = 'PORTFOLIO.OPTIMIZATION_COMPARISON',

    PORTFOLIO_REPORTS_DATA = 'PORTFOLIO_REPORTS.DATA',
    PORTFOLIO_REPORTS_UI_VARS = 'PORTFOLIO_REPORTS.UI_VARS',

    PORTFOLIO_GROUPS = 'PORTFOLIO_GROUPS',
    PORTFOLIO_GROUPS_UI_VARS = 'PORTFOLIO_GROUPS.UI_VARS',
    PORTFOLIO_GROUP_DETAILS_DATA = 'PORTFOLIO_GROUPS.DETAILS.DATA',
    PORTFOLIO_GROUP_PORTFOLIOS = 'PORTFOLIO_GROUPS.PORTFOLIOS',
    PORTFOLIO_GROUP_REPORT_PORTFOLIOS = 'PORTFOLIO_GROUPS.REPORT_PORTFOLIOS',

    STORY_MANAGER = 'STORY_MANAGER',
    STORY_MANAGER_DATABASES = 'STORY_MANAGER.DATABASES.DATA',
    STORY_MANAGER_UI_VARS = 'STORY_MANAGER.UI_VARS',
    STORY_MANAGER_DATABASE_DETAIL = 'STORY_MANAGER.DATABASE_DETAIL.DATA',
    STORY_MANAGER_OUTPUT_KEYS_UI_VARS = 'STORY_MANAGER.OUTPUT_KEYS.UI_VARS',
    STORY_MANAGER_OUTPUT_KEYS_SCENARIO_LIST_UI_VARS = 'STORY_MANAGER.OUTPUT_KEYS.SCENARIO_LIST.UI_VARS',

    RISK_PROFILES_DATA = 'RISK_PROFILES.DATA',
    RISK_PROFILES_UI_VARS = 'RISK_PROFILES.UI_VARS',

    RISK_PROFILE_DETAILS_DATA = 'RISK_PROFILE.DETAILS.DATA',

    GOALS_DATA = 'GOALS.DATA',
    GOALS_UI_VARS = 'GOALS.UI_VARS',
    GOAL_DETAILS_DATA = 'GOAL.DETAILS.DATA',

    HORIZONS_DATA = 'HORIZONS.DATA',
    HORIZONS_UI_VARS = 'HORIZONS.UI_VARS',
    HORIZON_DETAILS_DATA = 'HORIZON.DETAILS.DATA',

    INSTRUMENTS_DATA = 'INSTRUMENTS.DATA',
    INSTRUMENTS_UNIVERSE_UI_VARS = 'INSTRUMENTS_UNIVERSE.UI_VARS',
    INSTRUMENTS_UI_VARS = 'INSTRUMENTS.UI_VARS',
    INSTRUMENT_PERFORMANCE_PAST = 'INSTRUMENT.PERFORMANCE.PAST',

    API_ENTITY_VERSIONS_DATA = 'API_ENTITY.VERSIONS.DATA',
    API_ENTITY_VERSIONS_UI_VARS = 'API_ENTITY.VERSIONS.UI_VARS',

    ACCESSIBLE_BY_USERS_UI_VARS = 'ACCESSIBLE_BY.USERS.UI_VARS',
    ACCESSIBLE_BY_USERGROUPS_UI_VARS = 'ACCESSIBLE_BY.USERGROUPS.UI_VARS',

    MGMT_REPORTING_INFO_DATA = 'MGMT_REPORTING.INFO.DATA',
    MGMT_REPORTING_ROBO_UI_VARS = 'MGMT_REPORTING.ROBO.UI_VARS',
    MGMT_REPORTING_ROBO_AUM_DATA = 'MGMT_REPORTING.ROBO.AUM.DATA',
    MGMT_REPORTING_ROBO_CLIENT_GROWTH_DATA = 'MGMT_REPORTING.ROBO.CLIENT_GROWTH.DATA',
    MGMT_REPORTING_ROBO_CLIENT_CASH_TRANSFER_DATA = 'MGMT_REPORTING.ROBO.CLIENT_CASH_TRANSFER.DATA',
    MGMT_REPORTING_ROBO_PORTFOLIO_PERFORMANCE_DATA = 'MGMT_REPORTING.ROBO.PORTFOLIO_PERFORMANCE.DATA',

    MGMT_REPORTING_SELF_UI_VARS = 'MGMT_REPORTING.SELF.UI_VARS',
    MGMT_REPORTING_SELF_AUM_DATA = 'MGMT_REPORTING.SELF.AUM.DATA',
    MGMT_REPORTING_SELF_CLIENT_GROWTH_DATA = 'MGMT_REPORTING.SELF.CLIENT_GROWTH.DATA',
    MGMT_REPORTING_SELF_CLIENT_CASH_TRANSFER_DATA = 'MGMT_REPORTING.SELF.CLIENT_CASH_TRANSFER.DATA',
}
