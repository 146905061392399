import { IFormStatus, IFormValues } from 'views/common/inputs/extended/types';

export enum ExtendedInputFormName {
    horizonProperties = 'horizon_properties_form',
    goalProperties = 'goal_properties_form',
    riskProfileProperties = 'risk-profile_properties_form',
    userProperties = 'user_properties_form',
    userGroupMembers = 'user-group_members_form',
    userGroupOperationPermissions = 'user-group_operation-permissions_form',
    userGroupProperties = 'user-group_properties_form',
    policySettings = 'policy_settings_form',
    policyAddComposition = 'policy_add_composition_form',
    storyManagerOutputKeyDetails = 'story-manager_output-key-detail_form',
    storyManagerOutputKeyScenarioDetails = 'story-manager_output-key-scenario-detail_form',
    storyManagerCreateNewBranch = 'story-manager_create-new-branch_form',
    storyManagerAddOutputKey = 'story-manager_add-output-key_form',
}

interface IManagedExtendedInputForms {
    // eslint-disable-next-line @typescript-eslint/ban-types
    [formName: string]: IManagedExtendedInputForm<{}>;
}

interface IManagedExtendedInputForm<Values extends IFormValues> {
    reset: () => void;
    formState?: IManagedFormState<Values>; // DO NOT DO MODIFICATIONS ON THE VALUES WITHIN THIS
}

interface IManagedFormState<Values extends IFormValues> extends IFormStatus {
    values: Values;
}

const formsMap: IManagedExtendedInputForms = {};

export function getManagedExtendedInputForm<Values extends IFormValues>(
    formName: ExtendedInputFormName,
) {
    return formsMap[formName] as IManagedExtendedInputForm<Values>;
}

export function setManagedExtendedInputForm<Values extends IFormValues>(
    formName: ExtendedInputFormName,
    form: IManagedExtendedInputForm<Values>,
) {
    formsMap[formName] = form;
}

export function resetExtendedInputForms(formNamePrefix: string) {
    Object.keys(formsMap)
        .filter((formName) => formName.startsWith(formNamePrefix))
        .forEach((formName) => formsMap[formName] && formsMap[formName].reset());
}
