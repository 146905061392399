export const USER_TAB_KEY = {
    PROPERTIES: 'properties',
    PORTFOLIOS: 'portfolios',
    PORTFOLIO_REPORTS: 'portfolio_reports',
    ACCESSIBLE_BY: 'accessible_by',
};

export const DEFAULT_USER_TAB_KEY = USER_TAB_KEY.PROPERTIES;

export const USERGROUP_TAB_KEY = {
    OPERATION_PERMISSIONS: 'operation_permissions',
    MEMBERS: 'members',
    PROPERTIES: 'properties',
    ACCESSIBLE_BY: 'accessible_by',
};

export const DEFAULT_USERGROUP_TAB_KEY = USERGROUP_TAB_KEY.OPERATION_PERMISSIONS;

export const CLIENT_TAB_KEY = {
    PROPERTIES: 'properties',
    PORTFOLIOS: 'portfolios',
    PORTFOLIO_REPORTS: 'portfolio_reports',
    ACCESSIBLE_BY: 'accessible_by',
};

export const DEFAULT_CLIENT_TAB_KEY = CLIENT_TAB_KEY.PROPERTIES;

export const PORTFOLIO_TAB_KEY = {
    PERFORMANCE: 'performance',
    ALLOCATION: 'allocation',
    HOLDINGS: 'holdings',
    OPTIMIZATIONS: 'optimizations',
    PROPERTIES: 'properties',
    TRANSACTIONS: 'transactions',
    ACCESSIBLE_BY: 'accessible_by',
    REPORTS: 'reports',
};

export const DEFAULT_PORTFOLIO_TAB_KEY = PORTFOLIO_TAB_KEY.PERFORMANCE;

export const PORTFOLIO_GROUP_DETAILS_TAB_KEY = {
    PORTFOLIOS: 'portfolios',
    DETAILS: 'details',
    ACCESSIBLE_BY: 'accessible_by',
    GROUP_REPORTS: 'group_reports',
};

export const DEFAULT_PORTFOLIO_GROUP_DETAILS_TAB_KEY = PORTFOLIO_GROUP_DETAILS_TAB_KEY.PORTFOLIOS;

export const RISK_PROFILE_TAB_KEY = {
    PROPERTIES: 'properties',
    ACCESSIBLE_BY: 'accessible_by',
};

export const DEFAULT_RISK_PROFILE_TAB_KEY = RISK_PROFILE_TAB_KEY.PROPERTIES;

export const GOAL_TAB_KEY = {
    PROPERTIES: 'properties',
    ACCESSIBLE_BY: 'accessible_by',
};

export const DEFAULT_GOAL_TAB_KEY = GOAL_TAB_KEY.PROPERTIES;

export const HORIZON_TAB_KEY = {
    PROPERTIES: 'properties',
    ACCESSIBLE_BY: 'accessible_by',
};

export const DEFAULT_HORIZON_TAB_KEY = HORIZON_TAB_KEY.PROPERTIES;

export const POLICY_TAB_KEY = {
    CONFIGURATION: 'configuration',
    ACCESSIBLE_BY: 'accessible_by',
};

export const DEFAULT_POLICY_TAB_KEY = POLICY_TAB_KEY.CONFIGURATION;

export const POLICY_SIMULATION_TAB_KEY = {
    EXPECTED_PERFORMANCE: 'expected_performance',
    BACK_TEST: 'back_test',
};

export const DEFAULT_POLICY_SIMULATION_TAB_KEY = POLICY_SIMULATION_TAB_KEY.EXPECTED_PERFORMANCE;

export const MGMT_REPORTING_TAB_KEY = {
    ROBO_ADVISOR: 'robo_advisor',
    SELF_INVESTOR: 'self_investor',
};

export const DEFAULT_MGMT_REPORTING_TAB_KEY = MGMT_REPORTING_TAB_KEY.ROBO_ADVISOR;

export const POLICY_META_COMPOSITION_TAB_KEY = {
    RISK_BASED_POLICIES: 'risk_based_policies',
    MODEL_PORTFOLIO_POLICIES: 'model_portfolio_policies',
    PORTFOLIOS: 'portfolios',
};

export const DEFAULT_POLICY_META_COMPOSITION_TAB_KEY = POLICY_META_COMPOSITION_TAB_KEY.RISK_BASED_POLICIES;
