import React from 'react';
import { PortfolioManagerType, PortfolioMoneyType } from '@console/core-api/models/portfolioMgmt/portfolio.models';
import { getPortfolioActiveFieldDescriptionLabel, isPortfolioActiveFieldApplicable } from 'utils/entities/portfolioMgmt/portfolioUtils';
import { IExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import ExtendedInputText from 'views/common/inputs/extended/ExtendedInputText';
import ExtendedInputToggle from 'views/common/inputs/extended/ExtendedInputToggle';
import { IPortfolioFormValues, TPortfolioDetailsFormValues } from './types';
import { PortfolioManagerTypeFormContent, PortfolioMoneyTypeFormContent } from './portfolioTypeFormContents';

export function PortfolioNameFormContent({
    fields,
}: IExtendedInputFormContext<Pick<IPortfolioFormValues, 'name'>>) {
    return (
        <ExtendedInputText<string>
            formField={fields.name}
            wrapper={{
                label: 'fields.name.label',
            }}
        />
    );
}

export function PortfolioExternalIdFormContent({
    fields,
}: IExtendedInputFormContext<Pick<IPortfolioFormValues, 'externalId'>>) {
    return (
        <ExtendedInputText<string>
            formField={fields.externalId}
            wrapper={{
                label: 'fields.external_id.label',
                tooltip: 'fields.external_id.tip',
            }}
        />
    );
}

export function PortfolioDetailsFormContent(
    context: IExtendedInputFormContext<TPortfolioDetailsFormValues>,
) {
    return (
        <>
            <PortfolioNameFormContent {...context} />

            <PortfolioExternalIdFormContent {...context} />

            <PortfolioManagerTypeFormContent
                {...context}
                readOnlyForm
            />

            <PortfolioMoneyTypeFormContent
                {...context}
                readOnlyForm
            />

            {isPortfolioActiveFieldApplicable({
                managerType: context.fields.managerType.value as PortfolioManagerType,
                moneyType: context.fields.moneyType.value as PortfolioMoneyType,
            }) && (
                <ExtendedInputToggle
                    formField={context.fields.active}
                    description={getPortfolioActiveFieldDescriptionLabel({
                        managerType: context.fields.managerType.value as PortfolioManagerType,
                    })}
                    shouldPrefixDescription={false}
                    wrapper={{
                        label: 'fields.active.label',
                    }}
                />
            )}
        </>
    );
}
