import assert from '@snipsonian/core/cjs/assert';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { IApiEntityListResponse, IApiEntityResponseSupportingEmbedding } from '../../models/api.models';
import { CoreApiEntityType } from '../../config/coreEntities.config';
import {
    IFetchEntityVersionsApiInput,
    IApiEntityVersions,
    IFetchEntityVersionsComparisonApiInput,
    IApiEntityVersionsComparisonResponse,
    IEnhancedApiEntityVersionsComparisonField,
    TVersionEmbeddableFields,
} from '../../models/apiEntityVersions.models';
import {
    DEFAULT_PAGE_ITEM_LIMIT,
    FIRST_PAGE_NR,
} from '../../config/coreApi.config';
import { CoreApiPath } from '../../config/coreApiUrls.config';
import fetchApiEntityUrlParamBuilder from '../../utils/fetch/fetchApiEntityUrlParamBuilder';
import { enhanceVersionsComparisonResponse } from '../../utils/entities/versions/versionsComparisonEnhancer';
import { get } from '../coreApiRequestWrapper';

const VERSIONS_API_CONFIG: { [key in CoreApiEntityType]: { url: string; idPathParam: string }; } = {
    [CoreApiEntityType.goals]: { url: CoreApiPath.GOAL_VERSIONS, idPathParam: 'goalId' },
    [CoreApiEntityType.horizons]: { url: CoreApiPath.HORIZON_VERSIONS, idPathParam: 'horizonId' },
    [CoreApiEntityType.riskProfiles]: { url: CoreApiPath.RISK_PROFILE_VERSIONS, idPathParam: 'riskProfileId' },
    [CoreApiEntityType.portfolios]: { url: CoreApiPath.PORTFOLIO_VERSIONS, idPathParam: 'portfolioId' },
    // eslint-disable-next-line max-len
    [CoreApiEntityType.portfolioGroups]: { url: CoreApiPath.PORTFOLIO_GROUP_VERSIONS, idPathParam: 'portfolioGroupId' },
    [CoreApiEntityType.policies]: { url: CoreApiPath.POLICY_VERSIONS, idPathParam: 'policyId' },
    [CoreApiEntityType.optimizations]: null,
    [CoreApiEntityType.users]: { url: CoreApiPath.USER_VERSIONS, idPathParam: 'userId' },
    [CoreApiEntityType.userGroups]: { url: CoreApiPath.USERGROUP_VERSIONS, idPathParam: 'userGroupId' },
};

export function fetchApiEntityVersions<ApiEntityData = unknown>({
    type,
    id,
    offset,
    limit = DEFAULT_PAGE_ITEM_LIMIT,
    pageNr = FIRST_PAGE_NR,
}: IFetchEntityVersionsApiInput) {
    const apiConfig = getVersionsApiConfig(type);

    return get<
    IApiEntityVersions<ApiEntityData>,
    IApiEntityListResponse<ApiEntityData> & IApiEntityResponseSupportingEmbedding
    >({
        url: apiConfig.url,
        pathParams: {
            [apiConfig.idPathParam]: id,
        },
        queryParams: {
            ...fetchApiEntityUrlParamBuilder()
                .embed<TVersionEmbeddableFields>({ fields: 'version_authored_by_user_id' })
                .build(),
            offset,
            limit,
        },
        mapResponse: ({ data }) => ({
            type,
            id,
            pageNr,
            ...data,
        }),
    });
}

export function fetchApiEntityVersionsComparison({
    type,
    id,
    fromVersion,
    toVersion,
}: IFetchEntityVersionsComparisonApiInput) {
    const apiConfig = getVersionsApiConfig(type);

    return get<IEnhancedApiEntityVersionsComparisonField, IApiEntityVersionsComparisonResponse>({
        url: `${apiConfig.url}${CoreApiPath.VERSIONS_CHANGES_SUFFIX}`,
        pathParams: {
            [apiConfig.idPathParam]: id,
            fromVersion,
            toVersion,
        },
        mapResponse: ({ data }) => enhanceVersionsComparisonResponse(data),
    });
}

function getVersionsApiConfig(type: CoreApiEntityType) {
    const apiConfig = VERSIONS_API_CONFIG[type];

    assert(apiConfig, (val) => isSet(val), `No api config found for fetching the versions of type "${type}"`);

    return apiConfig;
}
