import { Locale } from '@console/common/config/i18n.config';
import { BankAccountType, BankIdType } from '@console/common/models/banking.models';
import {
    IBaseFetchEntityListApiInput,
    TApiEntity,
    IApiEntityListResponseWithPageNr, TApiEntityId,
    TApiEntityPatch, TApiEntityCreate,
} from '../api.models';

export interface IUserEntityData {
    brokerage_user_id?: string;
    email?: string;
    external_id?: string;
    feature_flags: UserFeatureFlag[];
    counter_account?: IUserCounterAccount;
    first_name: string;
    idp_user_id?: string;
    language: Locale;
    last_name: string;
    phone: string;
    status?: UserStatus;
    agreements?: IUserAgreements;
}

export interface IUserCounterAccount {
    bank_account_number_type: BankAccountType;
    bank_account_number: string;
    bank_id_type?: BankIdType;
    bank_id?: string;
    payment_reference?: string;
}

export enum UserFeatureFlag {
    Console = 'CONSOLE',
    RoboAdvisor = 'ROBO_ADVISOR',
    SelfInvestor = 'SELF_INVESTOR',
    Storyteller = 'STORYTELLER',
}

export enum UserStatus {
    WAITING_FOR_VERIFICATION = 'WAITING_FOR_VERIFICATION',
    WAITING_FOR_SIGNATURE = 'WAITING_FOR_SIGNATURE',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export interface IUserAgreements {
    [agreementId: string]: IUserAgreement;
}

export interface IUserAgreement {
    status: UserAgreementStatus;
    document_version: number; /* Version of the current agreed document. Minimum 1. */
    datetime: string;
}

export enum UserAgreementStatus {
    AGREED = 'AGREED',
    REJECTED = 'REJECTED',
}

export type TUsersData = IApiEntityListResponseWithPageNr<IUserEntityData>;
export type TUser = TApiEntity<IUserEntityData>;
export type TTUserPatch = Omit<TApiEntityPatch<IUserEntityData>, 'email' | 'idp_user_id'>;
export type TTUserCreate = TApiEntityCreate<IUserEntityData>;

export interface IFetchUsersApiInput extends IBaseFetchEntityListApiInput {
    email?: string; // contains check
    externalId?: string; // equals check
    lastName?: string; // contains check
    phoneNumber?: string; // contains check
    virtualIban?: string; // equals check
    brokerageUserId?: string; // equals check
    isClientSearch?: boolean; // in case clients are being fetched - default false
    featureFlags?: UserFeatureFlag[]; // field in check
    userIds?: TApiEntityId[]; // field in check
}

export interface ISingleUserApiInput {
    userId: TApiEntityId;
}

export interface IAdvancedUserFilters {
    email: string;
    externalId: string;
    lastName: string;
    phoneNumber: string;
    virtualIban: string;
    brokerageUserId: string;
    featureFlags: UserFeatureFlag[];
}

export interface IGroupThatUserBelongsTo {
    id: TApiEntityId;
    name: string;
    permissions: string[];
}
