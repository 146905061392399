import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import { isRoboPortfolioManagerType } from '@console/core-api/utils/entities/portfolios/portfolioTypeUtils';
import { IEnhancedPortfolioEntityData } from '@console/bff/models/portfolios/enhancedPortfolioDetails.models';
import { TUser } from '@console/core-api/models/userMgmt/user.models';
import { formatAmount } from '@console/common/utils/number/amountUtils';
import {
    IEsgSuitabilityProfilerSummaryResult,
    IPortfolioBrokerageAccount,
    PortfolioMoneyType,
} from '@console/core-api/models/portfolioMgmt/portfolio.models';
import { TApiEntity } from '@console/core-api/models/api.models';
import { PolicyManagerTag, TPolicy } from '@console/core-api/models/portfolioMgmt/policy.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import {
    USER_TAB_KEY, DEFAULT_USER_TAB_KEY,
    DEFAULT_GOAL_TAB_KEY, DEFAULT_HORIZON_TAB_KEY,
    DEFAULT_POLICY_TAB_KEY, DEFAULT_RISK_PROFILE_TAB_KEY,
} from 'config/tabs.config';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { apiCacheManager } from 'api/cache/apiCacheManager';
import { triggerFetchUserDetails, userDetailsEntity } from 'state/entities/userMgmt/userDetails';
import {
    portfolioDetailsEntity,
    triggerClearPortfolioBrokerageAccount,
    triggerPatchPortfolioDetails,
} from 'state/entities/portfolioMgmt/portfolioDetails';
import { getCurrentRouteLocation } from 'state/ui/selectors';
import { getDefaultTranslationFromApiLabel } from 'state/i18n/selectors';
import {
    canUserAssignPortfolioToOtherOwner,
    canUserModifyPortfolio,
} from 'state/auth/apiEntityAuthorization.selectors';
import { formatBooleanForDisplay } from 'utils/boolean/booleanUtils';
import { formatDate, formatDateRelativeToNow } from '@console/common/utils/date/formatDate';
import {
    getPortfolioActiveFieldDescriptionLabel,
    getPortfolioManagerTypeLabel,
    isPortfolioActiveFieldApplicable,
    isPortfolioTagSet,
} from 'utils/entities/portfolioMgmt/portfolioUtils';
import { enrichApiEntityToEnsureUserAccess } from 'utils/entities/entityEnrichUtils';
import { getUserFullName } from 'utils/entities/userMgmt/userUtils';
import { ROUTE_KEY } from 'views/routeKeys';
import { redirectTo } from 'views/routes';
import { makeStyles, mixins } from 'views/styling';
import PropertiesCategoryBox from 'views/common/detail/PropertiesCategoryBox';
import { IDetailField } from 'views/common/detail/DetailFieldsList';
import { IEditForm } from 'views/common/detail/DetailFieldsListWithEditModal';
import { initEntityWrapper } from 'views/common/widget/EntityWrapper';
import { getScoreRangeMinMax } from 'views/portfolioMgmt/RiskProfiles/RiskProfileDetail/riskProfileDetailsSchema';
import {
    TPortfolioBrokerageAccountFormValues,
    TPortfolioDetailsFormValues,
    TPortfolioRoboRelatedFormValues,
    TPortfolioRiskProfileFormValues,
    TPortfolioOwnerFormValues,
} from 'views/portfolioMgmt/Portfolios/PortfolioDetail/portfolioFormContents/types';
import {
    PortfolioRiskProfileFormContent,
} from 'views/portfolioMgmt/Portfolios/PortfolioDetail/portfolioFormContents/portfolioRiskProfileFormContents';
import {
    PortfolioBrokerageAccountFormContent,
} from 'views/portfolioMgmt/Portfolios/PortfolioDetail/portfolioFormContents/portfolioBrokerageAccountFormContents';
import {
    PortfolioDetailsFormContent,
} from 'views/portfolioMgmt/Portfolios/PortfolioDetail/portfolioFormContents/portfolioDetailsFormContents';
import {
    PortfolioRoboRelatedFormContent,
} from 'views/portfolioMgmt/Portfolios/PortfolioDetail/portfolioFormContents/portfolioRoboRelatedFormContents';
import {
    PortfolioOwnerFormContent,
} from 'views/portfolioMgmt/Portfolios/PortfolioDetail/portfolioFormContents/portfolioVariaFormContents';
import {
    portfolioBrokerageAccountSchema,
    portfolioDetailsSectionSchema,
    portfolioRoboRelatedSchema,
    portfolioRiskProfileSchema,
    portfolioOwnerSchema,
} from './portfolioDetailsSchema';

const LABEL_PREFIX_DETAIL = 'portfolio_mgmt.portfolios.detail';
const LABEL_PREFIX_PROPERTIES = `${LABEL_PREFIX_DETAIL}.properties`;
const MAX_WIDTH_ESG_PROFILE = 700;

const useStyles = makeStyles((theme) => ({
    PortfolioMain: {
        ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'flex-start', wrap: 'wrap' }),

        '& .__column': {
            ...mixins.flexColTopLeft(),
            flexGrow: 1,
            padding: theme.spacing(0, 1),
        },
        '& .__box': {
            ...mixins.widthMax(),
            marginBottom: theme.spacing(2),
        },

        '& .__esgLabel': {
            ...mixins.flexRowCenterLeft(),

            '& svg': {
                marginRight: theme.spacing(1),
            },
        },
    },
}));

export function PortfolioProperties() {
    const classes = useStyles();

    const RISK_PROFILE_MAX_SCORE = getScoreRangeMinMax().MAX;

    const portfolioData = portfolioDetailsEntity.select().data;
    const isPortfolioReadOnly = !canUserModifyPortfolio(portfolioData);
    const isVirtual = (portfolioData.money_type === PortfolioMoneyType.PAPER_MONEY);
    const isRoboManaged = isRoboPortfolioManagerType(portfolioData.config.manager);
    const isBrokerageAccountSet = isSet(portfolioData?.brokerage_account);

    const OwnerEntityWrapper = initEntityWrapper({
        notifications: [StateChangeNotification.USER_DETAILS_DATA],
    });

    return (
        <div>
            <div className={classes.PortfolioMain}>
                <div className="__column">
                    {isRoboManaged && (
                        <PropertiesCategoryBox<TPortfolioRoboRelatedFormValues>
                            id="portfolio-robo-related-fields"
                            category="robo"
                            fields={getRoboRelatedPortfolioFields(portfolioData)}
                            labelPrefix={LABEL_PREFIX_DETAIL}
                            isReadOnly={isPortfolioReadOnly}
                            editGlobalForm={getEditRoboRelatedPortfolioFieldsForm(portfolioData)}
                        />
                    )}
                    <PropertiesCategoryBox<TPortfolioRiskProfileFormValues>
                        id="portfolio-risk-profile-fields"
                        category="risk_profile"
                        fields={getRiskProfileFields(portfolioData, RISK_PROFILE_MAX_SCORE)}
                        labelPrefix={LABEL_PREFIX_DETAIL}
                        editGlobalForm={getEditRiskProfileForm(portfolioData)}
                        isReadOnly={isPortfolioReadOnly}
                    />
                    <OwnerEntityWrapper
                        asyncEntitySelector={userDetailsEntity.select}
                        renderData={renderPortfolioOwner}
                        shouldRenderIfNoDataSet
                        setFullWidth
                    />
                </div>

                <div className="__column">
                    <PropertiesCategoryBox<TPortfolioDetailsFormValues>
                        id="portfolio-details-fields"
                        category="details"
                        fields={getDetailsFields(portfolioData)}
                        labelPrefix={LABEL_PREFIX_DETAIL}
                        isReadOnly={isPortfolioReadOnly}
                        editGlobalForm={getEditDetailsForm(portfolioData)}
                    />
                    {!isVirtual && (
                        <PropertiesCategoryBox<TPortfolioBrokerageAccountFormValues>
                            id="portfolio-brokerage-account-fields"
                            category="connections"
                            fields={getConnectionFields(portfolioData)}
                            labelPrefix={LABEL_PREFIX_DETAIL}
                            editGlobalForm={getEditConnectionsForm(portfolioData)}
                            clearAll={isBrokerageAccountSet && {
                                confirmationModal: {
                                    messageLabel: 'portfolio_mgmt.portfolios.detail.actions.clear_brokerage_account',
                                },
                                onClear: triggerClearPortfolioBrokerageAccount,
                            }}
                            isReadOnly={isPortfolioReadOnly}
                        />
                    )}
                    {isRoboManaged && isArrayWithValues(portfolioData.config.manager_settings.esg_profile) && (
                        <PropertiesCategoryBox
                            id="portfolio-manager-esg-profile"
                            category="esg_profile"
                            labelPrefix={LABEL_PREFIX_DETAIL}
                            shouldPrefixLabels={false}
                            fields={toEsgProfileFields(portfolioData.config.manager_settings.esg_profile)}
                            isReadOnly
                            maxWidth={MAX_WIDTH_ESG_PROFILE}
                        />
                    )}
                    {isRoboManaged && isArrayWithValues(portfolioData.config.manager_settings.proposed_esg_profile) && (
                        <PropertiesCategoryBox
                            id="portfolio-manager-proposed-esg-profile"
                            category="proposed_esg_profile"
                            labelPrefix={LABEL_PREFIX_DETAIL}
                            shouldPrefixLabels={false}
                            fields={toEsgProfileFields(portfolioData.config.manager_settings.proposed_esg_profile)}
                            isReadOnly
                            maxWidth={MAX_WIDTH_ESG_PROFILE}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

function renderPortfolioOwner({ data: ownerDetails }: { data: TUser }) {
    const portfolioData = portfolioDetailsEntity.select().data;

    const ownerFields: IDetailField[] = [{
        label: 'user_name.label',
        value: ownerDetails ? getUserFullName(ownerDetails) : null,
        link: {
            routeKey: ROUTE_KEY.R_USER_DETAIL,
            params: {
                userId: ownerDetails ? ownerDetails.id : null,
                userTab: DEFAULT_USER_TAB_KEY,
            },
        },
    }, {
        label: 'user_id.label',
        value: ownerDetails ? ownerDetails.id : null,
        condensedValue: true,
        copyValue: true,
    }, {
        label: 'user_portfolios_link.label',
        value: ownerDetails ? `${LABEL_PREFIX_DETAIL}.fields.user_portfolios_link.value` : null,
        shouldTranslateValue: !!ownerDetails,
        link: {
            routeKey: ROUTE_KEY.R_USER_DETAIL,
            params: {
                userId: ownerDetails ? ownerDetails.id : null,
                userTab: USER_TAB_KEY.PORTFOLIOS,
            },
        },
    }, {
        label: 'external_id.label',
        value: ownerDetails ? ownerDetails.external_id : null,
    }, {
        label: 'user_email.label',
        value: ownerDetails ? ownerDetails.email : null,
    }, {
        label: 'user_phone.label',
        value: ownerDetails ? ownerDetails.phone : null,
    }];

    const editOwnerForm: IEditForm<TPortfolioOwnerFormValues> = isPortfolioOwnerEditable()
        ? {
            initialValues: {
                owner: ownerDetails || null,
            },
            schema: portfolioOwnerSchema,
            renderFormFields: PortfolioOwnerFormContent,
            submit: {
                onSubmit: ({ values }) => triggerPatchPortfolioDetails((currentPortfolio) => {
                    // eslint-disable-next-line no-param-reassign
                    currentPortfolio.owned_by_user_id = (values.owner as TUser)?.id;

                    enrichApiEntityToEnsureUserAccess({
                        entity: currentPortfolio,
                        userId: (values.owner as TUser)?.id,
                    });
                }, {
                    onPreSuccess: ({ state }) => {
                        /** fetch the new owner's details */
                        triggerFetchUserDetails({ userId: (values.owner as TUser)?.id });
                        const currentRoute = getCurrentRouteLocation(state);

                        /**
                         * If the portfolio being transferred was actually opened from the user detail screen,
                         * then we do a redirect because otherwise the route params would not be correct anymore.
                         */
                        if (currentRoute.routeKey === ROUTE_KEY.R_USER_DETAIL_PORTFOLIO_DETAIL) {
                            redirectTo({
                                routeKey: ROUTE_KEY.R_PORTFOLIO_DETAIL,
                                params: currentRoute.params, // keep the portfolioId and -Tab
                            });
                        }
                    },
                }),
            },
            modalTitle: `${LABEL_PREFIX_PROPERTIES}.modal_titles.portfolio_owner`,
            modalMaxWidth: 'md',
        }
        : null;

    const isPortfolioOwnerReadOnly = !canUserAssignPortfolioToOtherOwner();

    function isPortfolioOwnerEditable() {
        return portfolioData
            ? portfolioData.money_type === PortfolioMoneyType.PAPER_MONEY
            : false;
    }

    return (
        <PropertiesCategoryBox<TPortfolioOwnerFormValues>
            id="portfolio-owner-fields"
            category="portfolio_owner"
            fields={ownerFields}
            labelPrefix={LABEL_PREFIX_DETAIL}
            editGlobalForm={editOwnerForm}
            isReadOnly={isPortfolioOwnerReadOnly}
        />
    );
}

function getDetailsFields(
    portfolio: TApiEntity<IEnhancedPortfolioEntityData>,
): IDetailField[] {
    const fields: IDetailField[] = [{
        label: 'name.label',
        value: portfolio.name,
    }, {
        label: 'external_id.label',
        value: portfolio.external_id,
        condensedValue: true,
        copyValue: true,
    }, {
        label: 'base_currency.label',
        value: portfolio.base_currency,
    }, {
        label: 'manager_type.label',
        value: getPortfolioManagerTypeLabel(portfolio.config.manager),
        shouldTranslateValue: true,
    }, {
        label: 'money_type.flag',
        value: formatBooleanForDisplay(portfolio.money_type === PortfolioMoneyType.PAPER_MONEY),
        shouldTranslateValue: true,
    }, {
        label: 'manager_version.label',
        value: portfolio.config.manager_version.toString(),
    }, {
        label: 'funded_since.label',
        value: formatDateRelativeToNow({
            date: portfolio.funded_since,
        }),
    }, {
        label: 'end_datetime.label',
        value: formatDateRelativeToNow({
            date: portfolio.config.manager_settings.end_datetime,
        }),
        hideIfValueUnset: true,
    }];

    if (isPortfolioActiveFieldApplicable({
        managerType: portfolio.config.manager,
        moneyType: portfolio.money_type,
    })) {
        fields.push({
            label: 'active.label',
            value: formatBooleanForDisplay(portfolio.config.manager_settings.active),
            shouldTranslateValue: true,
            tooltip: {
                label: getPortfolioActiveFieldDescriptionLabel({
                    managerType: portfolio.config.manager,
                }),
            },
        });
    }

    return fields;
}

function getRoboRelatedPortfolioFields(
    portfolio: TApiEntity<IEnhancedPortfolioEntityData>,
): IDetailField[] {
    return [{
        label: 'goal_id.label',
        value: portfolio.config.manager_settings.goal_id,
        asyncValueMapper: (value) => apiCacheManager.fetchGoal({
            goalId: value,
        }).then((goal) => goal && getDefaultTranslationFromApiLabel(goal.name)),
        link: {
            routeKey: ROUTE_KEY.R_GOAL_DETAIL,
            params: {
                goalId: portfolio.config.manager_settings.goal_id,
                goalTab: DEFAULT_GOAL_TAB_KEY,
            },
        },
    }, {
        label: 'horizon_id.label',
        value: portfolio.config.manager_settings.horizon_id,
        asyncValueMapper: (value) => apiCacheManager.fetchHorizon({
            horizonId: value,
        }).then((horizon) => horizon && getDefaultTranslationFromApiLabel(horizon.name)),
        link: {
            routeKey: ROUTE_KEY.R_HORIZON_DETAIL,
            params: {
                horizonId: portfolio.config.manager_settings.horizon_id,
                horizonTab: DEFAULT_HORIZON_TAB_KEY,
            },
        },
    }, {
        label: 'policy_id.label',
        value: portfolio.config.manager_settings.policy_id,
        asyncValueMapper: (value) => apiCacheManager.fetchPolicy({
            policyId: value,
        }).then((policy) => policy && policy.name),
        link: {
            routeKey: ROUTE_KEY.R_POLICY_DETAIL,
            params: {
                policyId: portfolio.config.manager_settings.policy_id,
                policyTab: DEFAULT_POLICY_TAB_KEY,
            },
        },
    }, {
        label: 'manager_tags.sharia_flag',
        value: formatBooleanForDisplay(isPortfolioTagSet(portfolio, PolicyManagerTag.SHARIA)),
        shouldTranslateValue: true,
    }, {
        label: 'manager_tags.esg_flag',
        value: formatBooleanForDisplay(isPortfolioTagSet(portfolio, PolicyManagerTag.ESG)),
        shouldTranslateValue: true,
    }, {
        label: 'start_amount.label',
        value: (portfolio.config.manager_settings.start_amount)
            ? formatAmount(portfolio.config.manager_settings.start_amount, {
                currency: portfolio.base_currency,
            })
            : null,
    }, {
        label: 'recurring_deposit_amount.label',
        value: (portfolio.config.manager_settings.recurring_deposit_amount)
            ? formatAmount(portfolio.config.manager_settings.recurring_deposit_amount, {
                currency: portfolio.base_currency,
            })
            : null,
    }, {
        label: 'divest_amount.label',
        value: (portfolio.config.manager_settings.divest_amount)
            ? formatAmount(portfolio.config.manager_settings.divest_amount, {
                currency: portfolio.base_currency,
            })
            : null,
    }, {
        label: 'optimization_cooldown_end.label',
        value: formatDate({
            date: portfolio.config.manager_settings.optimization_cooldown_end,
        }),
        hideIfValueUnset: true,
    }];
}

function getRiskProfileFields(
    portfolio: TApiEntity<IEnhancedPortfolioEntityData>,
    riskProfileMaxScore: number,
): IDetailField[] {
    return [{
        label: 'risk_profile_id.score',
        value: (portfolio.config.manager_settings.risk_profile_score)
            ? `${portfolio.config.manager_settings.risk_profile_score}/${riskProfileMaxScore}` : '-',
    }, {
        label: 'risk_profile_id.selected',
        value: portfolio.config.manager_settings.risk_profile_id,
        asyncValueMapper: (value) => apiCacheManager.fetchRiskProfile({
            riskProfileId: value,
        }).then((riskProfile) => riskProfile && getDefaultTranslationFromApiLabel(riskProfile.name)),
        link: {
            routeKey: ROUTE_KEY.R_RISK_PROFILE_DETAIL,
            params: {
                riskProfileId: portfolio.config.manager_settings.risk_profile_id,
                riskProfileTab: DEFAULT_RISK_PROFILE_TAB_KEY,
            },
        },
    }, {
        label: 'risk_profile_id.proposed',
        value: portfolio.config.manager_settings.proposed_risk_profile_id,
        asyncValueMapper: (value) => apiCacheManager.fetchRiskProfile({
            riskProfileId: value,
        }).then((riskProfile) => riskProfile && getDefaultTranslationFromApiLabel(riskProfile.name)),
    }];
}

function getConnectionFields(
    portfolio: TApiEntity<IEnhancedPortfolioEntityData>,
): IDetailField[] {
    return [{
        label: 'brokerage_user_id.label',
        value: portfolio.brokerage_account?.brokerage_user_id,
        copyValue: true,
    }, {
        label: 'brokerage_portfolio_id.label',
        value: portfolio.brokerage_account?.brokerage_portfolio_id,
        condensedValue: true,
        copyValue: true,
    }, {
        label: 'bank_account_type.label',
        value: portfolio.brokerage_account?.bank_account_type,
    }, {
        label: 'bank_account_number.label',
        value: portfolio.brokerage_account?.bank_account_number,
        condensedValue: true,
        copyValue: true,
    }, {
        label: 'bank_id.label',
        value: portfolio.brokerage_account?.bank_id,
    }, {
        label: 'payment_reference.label',
        value: portfolio.brokerage_account?.payment_reference,
    }];
}

function getEditRiskProfileForm(
    portfolio: TApiEntity<IEnhancedPortfolioEntityData>,
): IEditForm<TPortfolioRiskProfileFormValues> {
    return {
        initialValues: {
            riskProfileId: portfolio.config.manager_settings.risk_profile_id,
            managerType: portfolio.config.manager,
            riskProfileScore: portfolio.config.manager_settings.risk_profile_score,
            proposedRiskProfileId: portfolio.config.manager_settings.proposed_risk_profile_id,
        },
        schema: portfolioRiskProfileSchema,
        renderFormFields: PortfolioRiskProfileFormContent,
        submit: {
            onSubmit: ({ values }) => triggerPatchPortfolioDetails((currentPortfolio) => {
                // eslint-disable-next-line no-param-reassign
                currentPortfolio.config.manager_settings.risk_profile_id = values.riskProfileId;
            }),
        },
        modalTitle: `${LABEL_PREFIX_PROPERTIES}.modal_titles.risk_profile`,
        modalMaxWidth: 'xs',
        labelPrefix: LABEL_PREFIX_DETAIL,
    };
}

function getEditConnectionsForm(
    portfolio: TApiEntity<IEnhancedPortfolioEntityData>,
): IEditForm<TPortfolioBrokerageAccountFormValues> {
    const hasBrokerageAccount = (portfolio.brokerage_account);

    return {
        initialValues: {
            bankAccountNumber: (hasBrokerageAccount) ? portfolio.brokerage_account.bank_account_number : '',
            bankAccountType: (hasBrokerageAccount) ? portfolio.brokerage_account.bank_account_type : null,
            bankId: (hasBrokerageAccount) ? portfolio.brokerage_account.bank_id : '',
            brokeragePortfolioId: (hasBrokerageAccount) ? portfolio.brokerage_account.brokerage_portfolio_id : '',
            brokerageUserId: (hasBrokerageAccount) ? portfolio.brokerage_account.brokerage_user_id : '',
            paymentReference: (hasBrokerageAccount) ? portfolio.brokerage_account.payment_reference : '',
        },
        schema: portfolioBrokerageAccountSchema,
        renderFormFields: PortfolioBrokerageAccountFormContent,
        submit: {
            onSubmit: ({ values }) => triggerPatchPortfolioDetails((currentPortfolio) => {
                /* eslint-disable no-param-reassign */
                if (!hasBrokerageAccount) {
                    currentPortfolio.brokerage_account = {} as IPortfolioBrokerageAccount;
                }
                currentPortfolio.brokerage_account.bank_account_number = values.bankAccountNumber;
                currentPortfolio.brokerage_account.bank_account_type = values.bankAccountType;
                currentPortfolio.brokerage_account.bank_id = isSetString(values.bankId) ? values.bankId : null;
                currentPortfolio.brokerage_account.brokerage_portfolio_id = values.brokeragePortfolioId;
                currentPortfolio.brokerage_account.brokerage_user_id = values.brokerageUserId;
                currentPortfolio.brokerage_account.payment_reference = values.paymentReference;
                /* eslint-enable no-param-reassign */
            }),
        },
        modalTitle: `${LABEL_PREFIX_PROPERTIES}.modal_titles.connections`,
        modalMaxWidth: 'xs',
        labelPrefix: LABEL_PREFIX_DETAIL,
    };
}

function getEditRoboRelatedPortfolioFieldsForm(
    portfolio: TApiEntity<IEnhancedPortfolioEntityData>,
): IEditForm<TPortfolioRoboRelatedFormValues> {
    return {
        initialValues: {
            goalId: portfolio.config.manager_settings.goal_id,
            horizonId: portfolio.config.manager_settings.horizon_id,
            policy: {
                id: portfolio.config.manager_settings.policy_id,
            },
            tags: portfolio.config.manager_settings.tags,
            managerType: portfolio.config.manager,
            startAmount: portfolio.config.manager_settings.start_amount,
            divestAmount: portfolio.config.manager_settings.divest_amount,
            recurringAmount: portfolio.config.manager_settings.recurring_deposit_amount,
        },
        schema: portfolioRoboRelatedSchema,
        renderFormFields: PortfolioRoboRelatedFormContent,
        submit: {
            onSubmit: ({ values }) => triggerPatchPortfolioDetails((currentPortfolio) => {
                /* eslint-disable no-param-reassign */
                currentPortfolio.config.manager_settings.goal_id = values.goalId as string;
                currentPortfolio.config.manager_settings.horizon_id = values.horizonId as string;
                currentPortfolio.config.manager_settings.policy_id = (values.policy as TPolicy).id;
                currentPortfolio.config.manager_settings.tags = values.tags as PolicyManagerTag[];
                /* eslint-enable no-param-reassign */
            }),
        },
        modalTitle: `${LABEL_PREFIX_PROPERTIES}.modal_titles.policy`,
        modalMaxWidth: 'xs',
        labelPrefix: LABEL_PREFIX_DETAIL,
    };
}

function getEditDetailsForm(
    portfolio: TApiEntity<IEnhancedPortfolioEntityData>,
): IEditForm<TPortfolioDetailsFormValues> {
    return {
        initialValues: {
            name: portfolio.name,
            externalId: portfolio.external_id,
            active: portfolio.config.manager_settings.active,
            managerType: portfolio.config.manager,
            moneyType: portfolio.money_type,
        },
        schema: portfolioDetailsSectionSchema,
        renderFormFields: PortfolioDetailsFormContent,
        submit: {
            onSubmit: ({ values }) => triggerPatchPortfolioDetails((currentPortfolio) => {
                /* eslint-disable no-param-reassign */
                currentPortfolio.name = values.name as string;
                currentPortfolio.external_id = values.externalId as string;
                currentPortfolio.config.manager_settings.active = values.active;
                /* eslint-enable no-param-reassign */
            }),
        },
        modalTitle: `${LABEL_PREFIX_PROPERTIES}.modal_titles.details`,
        modalMaxWidth: 'xs',
        labelPrefix: LABEL_PREFIX_DETAIL,
    };
}

function toEsgProfileFields(esgProfilerResults: IEsgSuitabilityProfilerSummaryResult[]): IDetailField[] {
    return esgProfilerResults.map((esgProfilerResult) => ({
        label: esgProfilerResult.title,
        value: esgProfilerResult.description,
        customLabelRender: ({ translator }) => {
            const defaultTranslation = getDefaultTranslationFromApiLabel(esgProfilerResult.title);
            const selectionTooltipTranslation = translator(
                `${LABEL_PREFIX_DETAIL}.fields.esg_profile.${esgProfilerResult.selected ? 'is_selected' : 'is_not_selected'}`,
            );
            const SelectionIcon = esgProfilerResult.selected ? ToggleOnIcon : ToggleOffOutlinedIcon;
            return (
                <div className="__esgLabel">
                    <Tooltip title={selectionTooltipTranslation}>
                        <SelectionIcon />
                    </Tooltip>
                    {defaultTranslation}
                </div>
            );
        },
    }));
}
