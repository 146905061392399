import isSet from '@snipsonian/core/cjs/is/isSet';
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import doArraysHaveSameValues from '@snipsonian/core/cjs/array/verification/doArraysHaveSameValues';
import { IAsyncItem, TApiEntity } from '@console/core-api/models/api.models';
import { PolicyManagerTag, TPolicy } from '@console/core-api/models/portfolioMgmt/policy.models';
import { isRoboPortfolioManagerType } from '@console/core-api/utils/entities/portfolios/portfolioTypeUtils';
import { IEnhancedPortfolioEntityData } from '@console/bff/models/portfolios/enhancedPortfolioDetails.models';
import {
    PortfolioManagerType,
    PortfolioMoneyType,
} from '@console/core-api/models/portfolioMgmt/portfolio.models';
import { TEnhancedPolicy } from '@console/bff/models/policies/enhancedPolicyDetails.models';
import { getTenantSettings } from 'state/appConfig/selectors';

const PF_LABEL_PREFIX = 'portfolio_mgmt.portfolios';
const PF_DATA_LABEL_PREFIX = `${PF_LABEL_PREFIX}.data`;

export function isUserManagedPortfolio(
    portfolio: TApiEntity<IEnhancedPortfolioEntityData>,
): boolean {
    return isSelfPortfolioManagerType(portfolio?.config.manager);
}

export function isSelfPortfolioManagerType(portfolioManagerType: PortfolioManagerType): boolean {
    return portfolioManagerType === PortfolioManagerType.USER_MANAGED;
}

/**
 * Returns true when the 'active' field (of portfolio settings - has a different meaning in case of ROBO or SELF)
 * should be manageable by the user.
 *
 * During create:
 * - the back-end will auto-determine the value (for e.g. BDP it will always be false) so FE should not pass it as input
 *
 * During patch:
 * - only for real-money portfolio's
 * - in case of ROBO portfolio's: not for certain tenants like BDP if they do not use our optimizer
 */
export function isPortfolioActiveFieldApplicable({
    managerType,
    moneyType,
}: {
    managerType: PortfolioManagerType;
    moneyType: PortfolioMoneyType;
}): boolean {
    if (moneyType !== PortfolioMoneyType.REAL_MONEY) {
        return false;
    }

    if (isRoboPortfolioManagerType(managerType)) {
        return getTenantSettings()?.portfolios?.roboOptimizer?.isEnabled;
    }

    /* SELF */
    return true;
}

export function getPortfolioActiveFieldDescriptionLabel({
    managerType,
}: {
    managerType: PortfolioManagerType;
}): string {
    return `${PF_LABEL_PREFIX}.detail.fields.active.toggle.${isRoboPortfolioManagerType(managerType) ? 'robo' : 'self'}`;
}

export function getPortfolioManagerTypeLabel(managerType: PortfolioManagerType): string {
    return `${PF_DATA_LABEL_PREFIX}.manager_type.${managerType.toLowerCase()}`;
}

export function getPortfolioMoneyTypeLabel(moneyType: PortfolioMoneyType): string {
    return `${PF_DATA_LABEL_PREFIX}.money_type.${moneyType.toLowerCase()}`;
}

export function getPortfolioCombinedTypeLabel({
    managerType,
    moneyType,
}: {
    managerType: PortfolioManagerType;
    moneyType: PortfolioMoneyType;
}): string {
    return `${PF_DATA_LABEL_PREFIX}.combined_type.${managerType.toLowerCase()}.${moneyType.toLowerCase()}`;
}

export function isPortfolioTagSet(
    portfolio: TApiEntity<IEnhancedPortfolioEntityData>,
    portfolioTag: PolicyManagerTag,
): boolean {
    return portfolio.config.manager_settings.tags.includes(portfolioTag);
}

export function isPortfolioLinkedToBroker(
    portfolio: TApiEntity<IEnhancedPortfolioEntityData>,
): boolean {
    if (isSet(portfolio.brokerage_account) && isSetString(portfolio.brokerage_account.brokerage_user_id)) {
        return true;
    }

    return false;
}

export function doPortfolioTagsMatchTheLinkedPolicyTags({
    portfolioTags,
    asyncLinkedPolicy,
}: {
    portfolioTags: PolicyManagerTag[];
    asyncLinkedPolicy: IAsyncItem<TPolicy | TEnhancedPolicy>;
}): boolean {
    if (asyncLinkedPolicy.data) {
        return doArraysHaveSameValues(
            portfolioTags || [],
            asyncLinkedPolicy.data.tags || [],
        );
    }

    /* by default return true so that we don't show the warning while the policy e.g. is still being fetched */
    return true;
}
