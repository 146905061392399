import { OperationPermissionKey } from '@typsy/console-api-client/dist/config/operationPermissionKeys';
import { ITenantSettings } from '@console/bff/models/settings/tenantSettings.models';

interface IHiddenOperationPermissionKeysMap {
    [permissionKey: string]: true;
}

export function getHiddenOperationPermissionKeysMap(
    tenantSettings: ITenantSettings,
): IHiddenOperationPermissionKeysMap {
    const hiddenKeysMap: IHiddenOperationPermissionKeysMap = {
        [OperationPermissionKey.SYSTEM_FIELD_MODIFY]: true,
    };

    if (!tenantSettings?.storyteller?.manageReports?.isEnabled) {
        hiddenKeysMap[OperationPermissionKey.PORTFOLIOREPORT_CREATE] = true;
        hiddenKeysMap[OperationPermissionKey.PORTFOLIOREPORT_READ] = true;
        hiddenKeysMap[OperationPermissionKey.PORTFOLIOREPORT_MODIFY] = true;

        hiddenKeysMap[OperationPermissionKey.PORTFOLIO_GROUPS_CREATE] = true;
        hiddenKeysMap[OperationPermissionKey.PORTFOLIO_GROUPS_READ] = true;
        hiddenKeysMap[OperationPermissionKey.PORTFOLIO_GROUPS_MODIFY] = true;
    }

    if (!tenantSettings?.storyteller?.storyManager?.isEnabled) {
        hiddenKeysMap[OperationPermissionKey.STORYMANAGER_READ] = true;
        hiddenKeysMap[OperationPermissionKey.STORYMANAGER_SCENARIO_MODIFY] = true;
        hiddenKeysMap[OperationPermissionKey.STORYMANAGER_TEXTOUTPUT_MODIFY] = true;
        hiddenKeysMap[OperationPermissionKey.STORYMANAGER_PUBLISH] = true;
    }

    if (!tenantSettings?.documents?.isEnabled) {
        hiddenKeysMap[OperationPermissionKey.DOCUMENTS_CREATE] = true;
        hiddenKeysMap[OperationPermissionKey.DOCUMENTS_READ] = true;
        hiddenKeysMap[OperationPermissionKey.DOCUMENTS_MODIFY] = true;
    }

    if (!tenantSettings?.cms?.contactSubjects?.isEnabled) {
        hiddenKeysMap[OperationPermissionKey.CMS_CONTACT_SUBJECTS_CREATE] = true;
        hiddenKeysMap[OperationPermissionKey.CMS_CONTACT_SUBJECTS_READ] = true;
        hiddenKeysMap[OperationPermissionKey.CMS_CONTACT_SUBJECTS_MODIFY] = true;
    }

    if (!tenantSettings?.cms?.countries?.isEnabled) {
        hiddenKeysMap[OperationPermissionKey.CMS_COUNTRIES_CREATE] = true;
        hiddenKeysMap[OperationPermissionKey.CMS_COUNTRIES_READ] = true;
        hiddenKeysMap[OperationPermissionKey.CMS_COUNTRIES_MODIFY] = true;
    }

    if (!tenantSettings?.cms?.personas?.isEnabled) {
        hiddenKeysMap[OperationPermissionKey.CMS_PERSONAS_CREATE] = true;
        hiddenKeysMap[OperationPermissionKey.CMS_PERSONAS_READ] = true;
        hiddenKeysMap[OperationPermissionKey.CMS_PERSONAS_MODIFY] = true;
    }

    // TODO also hide OPTIMIZATION keys when tenant does not have robo?

    return hiddenKeysMap;
}

interface IOperationPermissionGroup {
    label: string;
    permissions: OperationPermissionKey[];
}

/* eslint-disable max-len */

export const OPERATION_PERMISSION_GROUPS: IOperationPermissionGroup[] = [{
    label: 'portfolio_mgmt.goals.title',
    permissions: [OperationPermissionKey.GOALS_CREATE, OperationPermissionKey.GOALS_MODIFY, OperationPermissionKey.GOALS_READ],
}, {
    label: 'portfolio_mgmt.horizons.title',
    permissions: [OperationPermissionKey.HORIZONS_CREATE, OperationPermissionKey.HORIZONS_MODIFY, OperationPermissionKey.HORIZONS_READ],
}, {
    label: 'portfolio_mgmt.risk_profiles.title',
    permissions: [OperationPermissionKey.RISKPROFILES_CREATE, OperationPermissionKey.RISKPROFILES_MODIFY, OperationPermissionKey.RISKPROFILES_READ],
}, {
    label: 'portfolio_mgmt.policies.title',
    permissions: [OperationPermissionKey.POLICIES_CREATE, OperationPermissionKey.POLICIES_MODIFY, OperationPermissionKey.POLICIES_READ],
}, {
    label: 'auth.operation_permission_groups.base_policies',
    permissions: [OperationPermissionKey.BASE_POLICIES_CREATE, OperationPermissionKey.BASE_POLICIES_MODIFY],
}, {
    label: 'portfolio_mgmt.portfolios.title',
    permissions: [OperationPermissionKey.PORTFOLIOS_CREATE, OperationPermissionKey.PORTFOLIOS_MODIFY, OperationPermissionKey.PORTFOLIOS_MODIFY_OWNER, OperationPermissionKey.PORTFOLIOS_READ],
}, {
    label: 'portfolio_mgmt.portfolio_groups.title',
    permissions: [OperationPermissionKey.PORTFOLIO_GROUPS_CREATE, OperationPermissionKey.PORTFOLIO_GROUPS_MODIFY, OperationPermissionKey.PORTFOLIO_GROUPS_READ],
}, {
    label: 'portfolio_mgmt.portfolio_reports.title',
    permissions: [OperationPermissionKey.PORTFOLIOREPORT_CREATE, OperationPermissionKey.PORTFOLIOREPORT_MODIFY, OperationPermissionKey.PORTFOLIOREPORT_READ],
}, {
    label: 'auth.operation_permission_groups.portfolio_valuations',
    permissions: [OperationPermissionKey.PORTFOLIOVALUATIONS_CREATE, OperationPermissionKey.PORTFOLIOVALUATIONS_MODIFY, OperationPermissionKey.PORTFOLIOVALUATIONS_READ],
}, {
    label: 'auth.operation_permission_groups.optimizations',
    permissions: [OperationPermissionKey.OPTIMIZATIONS_CREATE, OperationPermissionKey.OPTIMIZATIONS_MODIFY, OperationPermissionKey.OPTIMIZATIONS_READ],
}, {
    label: 'auth.operation_permission_groups.transactions',
    permissions: [OperationPermissionKey.TRANSACTIONS_CREATE, OperationPermissionKey.TRANSACTIONS_MODIFY, OperationPermissionKey.TRANSACTIONS_READ],
}, {
    label: 'user_mgmt.users.title',
    permissions: [OperationPermissionKey.USERS_CREATE, OperationPermissionKey.USERS_MODIFY, OperationPermissionKey.USERS_READ],
}, {
    label: 'auth.operation_permission_groups.agreements',
    permissions: [OperationPermissionKey.AGREEMENTS_CREATE, OperationPermissionKey.AGREEMENTS_MODIFY, OperationPermissionKey.AGREEMENTS_READ],
}, {
    label: 'user_mgmt.user_groups.title',
    permissions: [OperationPermissionKey.USERGROUPS_CREATE, OperationPermissionKey.USERGROUPS_MODIFY, OperationPermissionKey.USERGROUPS_READ],
}, {
    label: 'suitability_profiler.profilers.operation_permission_group',
    permissions: [OperationPermissionKey.SUITABILITYPROFILER_PROFILERS_CREATE, OperationPermissionKey.SUITABILITYPROFILER_PROFILERS_MODIFY, OperationPermissionKey.SUITABILITYPROFILER_PROFILERS_READ],
}, {
    label: 'suitability_profiler.questionnaires.operation_permission_group',
    permissions: [OperationPermissionKey.SUITABILITYPROFILER_QUESTIONNAIRES_CREATE, OperationPermissionKey.SUITABILITYPROFILER_QUESTIONNAIRES_MODIFY, OperationPermissionKey.SUITABILITYPROFILER_QUESTIONNAIRES_READ],
}, {
    label: 'suitability_profiler.questions.operation_permission_group',
    permissions: [OperationPermissionKey.SUITABILITYPROFILER_QUESTIONS_CREATE, OperationPermissionKey.SUITABILITYPROFILER_QUESTIONS_MODIFY, OperationPermissionKey.SUITABILITYPROFILER_QUESTIONS_READ],
}, {
    label: 'suitability_profiler.user_profiles.operation_permission_group',
    permissions: [OperationPermissionKey.SUITABILITYPROFILER_PROFILES_CREATE, OperationPermissionKey.SUITABILITYPROFILER_PROFILES_MODIFY, OperationPermissionKey.SUITABILITYPROFILER_PROFILES_READ],
}, {
    label: 'suitability_profiler.cooldown.operation_permission_group',
    permissions: [OperationPermissionKey.SUITABILITYPROFILERCOOLDOWN_CREATE, OperationPermissionKey.SUITABILITYPROFILERCOOLDOWN_MODIFY, OperationPermissionKey.SUITABILITYPROFILERCOOLDOWN_READ],
}, {
    label: 'suitability_profiler.profile_property_assignment.operation_permission_group',
    permissions: [OperationPermissionKey.SUITABILITYPROFILERPROFILEPROPERTYASSIGNMENT_CREATE, OperationPermissionKey.SUITABILITYPROFILERPROFILEPROPERTYASSIGNMENT_MODIFY, OperationPermissionKey.SUITABILITYPROFILERPROFILEPROPERTYASSIGNMENT_READ],
}, {
    label: 'suitability_profiler.user_property.operation_permission_group',
    permissions: [OperationPermissionKey.SUITABILITYPROFILERUSERPROPERTY_CREATE, OperationPermissionKey.SUITABILITYPROFILERUSERPROPERTY_MODIFY, OperationPermissionKey.SUITABILITYPROFILERUSERPROPERTY_READ],
}, {
    label: 'suitability_profiler.user_property_assignment.operation_permission_group',
    permissions: [OperationPermissionKey.SUITABILITYPROFILERUSERPROPERTYASSIGNMENT_CREATE, OperationPermissionKey.SUITABILITYPROFILERUSERPROPERTYASSIGNMENT_MODIFY, OperationPermissionKey.SUITABILITYPROFILERUSERPROPERTYASSIGNMENT_READ],
}, {
    label: 'suitability_profiler.profile_assessment.operation_permission_group',
    permissions: [OperationPermissionKey.SUITABILITYPROFILERPROFILEASSESSMENT_CREATE, OperationPermissionKey.SUITABILITYPROFILERPROFILEASSESSMENT_MODIFY, OperationPermissionKey.SUITABILITYPROFILERPROFILEASSESSMENT_READ],
}, {
    label: 'auth.operation_permission_groups.story_manager',
    permissions: [OperationPermissionKey.STORYMANAGER_READ, OperationPermissionKey.STORYMANAGER_SCENARIO_MODIFY, OperationPermissionKey.STORYMANAGER_TEXTOUTPUT_MODIFY, OperationPermissionKey.STORYMANAGER_PUBLISH],
}, {
    label: 'auth.operation_permission_groups.documents',
    permissions: [OperationPermissionKey.DOCUMENTS_CREATE, OperationPermissionKey.DOCUMENTS_MODIFY, OperationPermissionKey.DOCUMENTS_READ],
}, {
    label: 'auth.operation_permission_groups.mgmt_reporting',
    permissions: [OperationPermissionKey.MANAGEMENT_REPORTING_ROBO_READ, OperationPermissionKey.MANAGEMENT_REPORTING_SELF_READ],
}, {
    label: 'auth.operation_permission_groups.cms_records',
    permissions: [
        OperationPermissionKey.CMS_CONTACT_SUBJECTS_CREATE, OperationPermissionKey.CMS_CONTACT_SUBJECTS_MODIFY, OperationPermissionKey.CMS_CONTACT_SUBJECTS_READ,
        OperationPermissionKey.CMS_COUNTRIES_CREATE, OperationPermissionKey.CMS_COUNTRIES_MODIFY, OperationPermissionKey.CMS_COUNTRIES_READ,
        OperationPermissionKey.CMS_PERSONAS_CREATE, OperationPermissionKey.CMS_PERSONAS_MODIFY, OperationPermissionKey.CMS_PERSONAS_READ,
    ],
}, {
    label: 'auth.operation_permission_groups.other',
    permissions: [
        OperationPermissionKey.RISKPROFILEQUESTIONRESPONSE_CREATE, OperationPermissionKey.RISKPROFILEQUESTIONRESPONSE_MODIFY, OperationPermissionKey.RISKPROFILEQUESTIONRESPONSE_READ,
        OperationPermissionKey.ACL_MODIFY,
        OperationPermissionKey.SYSTEM_FIELD_MODIFY,
    ],
}];

/* eslint-enable max-len */

export const OPERATION_PERMISSION_KEY_TO_GROUP_LABEL_MAP: { [key in OperationPermissionKey]: string } =
    OPERATION_PERMISSION_GROUPS.reduce(
        (accumulator, permissionGroup) => {
            permissionGroup.permissions.forEach((permissionKey) => {
                accumulator[permissionKey] = permissionGroup.label;
            });
            return accumulator;
        },
        {} as { [key in OperationPermissionKey]: string },
    );
