import isSet from '@snipsonian/core/cjs/is/isSet';
import assert from '@snipsonian/core/cjs/assert';
import Brightness5OutlinedIcon from '@mui/icons-material/Brightness5Outlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';
import { IMenuItemBase } from 'models/state/ui.models';
import { IPathParams } from 'models/routing.models';
import { SIZES } from 'config/styling/sizes';
import { ROUTE_KEY } from 'views/routeKeys';
import {
    AppsIcon, AnalyticsIcon, DashboardIcon,
    UsersIcon, ClientsIcon,
    PortfolioMgmtIcon, PortfolioGroupsIcon, ReportIcon, ClapperBoardIcon,
} from 'views/common/icons';
import { DEFAULT_MGMT_REPORTING_TAB_KEY } from './tabs.config';

const TRANSLATION_PREFIX = 'app_shell.menu';
const TRANSLATION_SUFFIX = 'title';

export type TAppMenuStatus = 'collapsed' | 'top' | 'context' | 'full';

export type TMenuItem = IMenuItemToRoute | IMenuItemWithSubItems | IMenuItemToContextMenu;
export type TSubMenuItem = IMenuItemToRoute | IMenuItemToContextMenu;
export type TContextMenuItem = IMenuItemToRoute | IMenuItemWithSubItems;

export interface IMenuItemToRoute extends IMenuItemBase {
    routeKey: ROUTE_KEY;
    params?: IPathParams;
}

export interface IMenuItemWithSubItems extends IMenuItemBase {
    subItems: TSubMenuItem[];
}

export interface IMenuItemToContextMenu extends IMenuItemBase {
    contextItems: TContextMenuItem[];
}

interface IRouteAppMenuItemsMap {
    [routeKey: string]: IMenuItemToRoute;
}

const routeAppMenuItemsMapByRouteKey: IRouteAppMenuItemsMap = {};

export function getRouteAppMenuItemByRouteKey(routeKey: string) {
    return routeAppMenuItemsMapByRouteKey[routeKey];
}

interface IAppMenuItemsMapById {
    [id: number]: TMenuItem;
}

const appMenuItemsMapById: IAppMenuItemsMapById = {};

export function getAppMenuItemById(id: number) {
    return appMenuItemsMapById[id];
}

/**
 * If the input item is of level 'context', then this function will return the 'lowest'
 * parent menu item which has level 'top'.
 * Otherwise (already level 'top') the input item is just returned.
 */
export function getTopMenuItemBySelectedMenuItem(selectedMenuItem: IMenuItemBase): IMenuItemBase {
    if (!selectedMenuItem) {
        return null;
    }

    if (selectedMenuItem.level === 'top') {
        return selectedMenuItem;
    }

    return getTopMenuItemBySelectedMenuItem(getAppMenuItemById(selectedMenuItem.parentId));
}

let menuIdCounter = 0;

/**
 * Some rules:
 * - translation keys:
 *   > only provide the 'middle' part as they will be automatically altered by adding a pre- and suffix (.title)
 * - 'parent' property:
 *   > don't provide it as it will be filled in automatically (based on the nesting)
 */
export const APP_MENU_ITEMS: TMenuItem[] = enhanceMenuItems([{
//     translationKey: 'home',
//     routeKey: ROUTE_KEY.R_HOME,
//     icon: HomeIcon,
// }, {
    translationKey: 'dashboard',
    // routeKey: ROUTE_KEY.R_DASHBOARD,
    routeKey: ROUTE_KEY.R_HOME,
    icon: DashboardIcon,
}, {
    translationKey: 'user_mgmt',
    icon: UsersIcon,
    contextItems: [{
        translationKey: 'user_mgmt.sub.users',
        icon: PersonOutlineOutlinedIcon,
        routeKey: ROUTE_KEY.R_USERS_LIST,
    }, {
        translationKey: 'user_mgmt.sub.user_groups',
        icon: PeopleAltOutlinedIcon,
        routeKey: ROUTE_KEY.R_USER_GROUPS_LIST,
    }],
}, {
    translationKey: 'client_mgmt',
    icon: ClientsIcon,
    routeKey: ROUTE_KEY.R_CLIENTS_LIST,
}, {
    translationKey: 'portfolio_mgmt',
    icon: PortfolioMgmtIcon,
    contextItems: [{
        translationKey: 'portfolio_mgmt.sub.goals',
        iconFont: 'fa-bullseye',
        routeKey: ROUTE_KEY.R_GOALS_LIST,
    }, {
        translationKey: 'portfolio_mgmt.sub.horizons',
        icon: Brightness5OutlinedIcon,
        routeKey: ROUTE_KEY.R_HORIZONS_LIST,
    }, {
        translationKey: 'portfolio_mgmt.sub.risk_profiles',
        iconFont: 'fa-balance-scale',
        routeKey: ROUTE_KEY.R_RISK_PROFILES_LIST,
    }, {
        translationKey: 'portfolio_mgmt.sub.policies',
        icon: SettingsOutlinedIcon,
        routeKey: ROUTE_KEY.R_POLICIES_LIST,
    }, {
        translationKey: 'portfolio_mgmt.sub.portfolios',
        iconFont: 'fa-folder-open',
        routeKey: ROUTE_KEY.R_PORTFOLIOS_LIST,
    }, {
        translationKey: 'portfolio_mgmt.sub.portfolio_groups',
        icon: PortfolioGroupsIcon,
        routeKey: ROUTE_KEY.R_PORTFOLIO_GROUPS_LIST,
    }],
}, {
    translationKey: 'apps',
    icon: AppsIcon,
    subItems: [{
        translationKey: 'apps.sub.story_teller',
        contextItems: [{
            translationKey: 'apps.sub.story_teller.sub.story_manager',
            routeKey: ROUTE_KEY.R_STORY_MANAGER_VERSIONS,
            icon: ClapperBoardIcon,
        }, {
            translationKey: 'apps.sub.story_teller.sub.reports',
            routeKey: ROUTE_KEY.R_STORYTELLER_REPORTS,
            icon: ReportIcon,
        }],
    }],
}, {
    translationKey: 'mgmt_reporting',
    icon: AnalyticsIcon,
    routeKey: ROUTE_KEY.R_MGMT_REPORTING,
    params: {
        mgmtReportingTab: DEFAULT_MGMT_REPORTING_TAB_KEY,
    },
}, {
    translationKey: 'documentation',
    icon: SpeakerNotesOutlinedIcon,
    contextItems: [{
        translationKey: 'documentation.sub.releases',
        iconFont: 'far fa-clipboard',
        routeKey: ROUTE_KEY.R_RELEASES,
    }],
}]);

function enhanceMenuItems(topMenuItems: TMenuItem[]): TMenuItem[] {
    return topMenuItems.map((topMenuItem) => enhanceMenuItemRecursive(topMenuItem, null, false));
}

function enhanceMenuItemRecursive(menuItem: TMenuItem, parentMenuItem: TMenuItem, isSubItem: boolean): TMenuItem {
    /* eslint-disable no-param-reassign */
    menuItem.id = generateMenuItemId();
    menuItem.translationKey = `${TRANSLATION_PREFIX}.${menuItem.translationKey}.${TRANSLATION_SUFFIX}`;
    menuItem.parentId = parentMenuItem ? parentMenuItem.id : null;
    menuItem.isSubItem = isSubItem;
    menuItem.level = (parentMenuItem === null || (parentMenuItem.level === 'top' && isSubItem))
        ? 'top'
        : 'context';
    /* eslint-enable no-param-reassign */

    if (!menuItem.isSubItem) {
        // eslint-disable-next-line max-len
        assert(
            menuItem,
            (item) => isSet(item.icon) || isSet(item.iconFont),
            `The menuItem with translation "${menuItem.translationKey}", like all non-subItems, should have an icon!`,
        );
    }

    if (isMenuItemWithSubItems(menuItem)) {
        menuItem.subItems.forEach((subMenuItem) => {
            enhanceMenuItemRecursive(subMenuItem, menuItem, true);
        });
    } else if (isMenuItemToContextMenu(menuItem)) {
        menuItem.contextItems.forEach((contextMenuItem) => {
            enhanceMenuItemRecursive(contextMenuItem, menuItem, false);
        });
    } else if (isMenuItemToRoute(menuItem)) {
        routeAppMenuItemsMapByRouteKey[menuItem.routeKey] = menuItem;
    }

    appMenuItemsMapById[menuItem.id] = menuItem;

    return menuItem;
}

export function isMenuItemToRoute(menuItem: IMenuItemBase): menuItem is IMenuItemToRoute {
    return (menuItem as IMenuItemToRoute).routeKey !== undefined;
}

export function isMenuItemWithSubItems(menuItem: IMenuItemBase): menuItem is IMenuItemWithSubItems {
    return (menuItem as IMenuItemWithSubItems).subItems !== undefined;
}

export function isMenuItemToContextMenu(menuItem: IMenuItemBase): menuItem is IMenuItemToContextMenu {
    return (menuItem as IMenuItemToContextMenu).contextItems !== undefined;
}

function generateMenuItemId() {
    menuIdCounter += 1;
    return menuIdCounter;
}

export function getBodyShiftWidthByAppMenuStatus(appMenuStatus: TAppMenuStatus) {
    switch (appMenuStatus) {
        case 'context':
            return SIZES.MENU.CONTEXT_WIDTH;
        case 'full':
            return SIZES.MENU.TOP_WIDTH + SIZES.MENU.CONTEXT_WIDTH;
        case 'collapsed':
            return SIZES.MENU.COLLAPSED_WIDTH;
        default:
            return SIZES.MENU.TOP_WIDTH;
    }
}
